import { DateString, DBFormatDateTime, Flag, OrderDir } from "types/util"
import { Attribute } from "resources/attribute/attributeTypes"
import {
  AllDataAggregationsResponse,
  AttributeAggregation,
  AttributeAggregationCreatePayload,
  AttributeAggregationListResponse,
  AttributeAggregationModifyPayload,
  AttributeAggregationReturnType,
} from "types/attributeAggregations"
import { Source } from "resources/dataSource/dataSourceTypes"
import {
  CustomerAttributeValueCount,
  CustomerAttributeValueCountResponse,
} from "types/customerAttributes"
import {
  FetchUserCountsResponse,
  FetchUsersActivityRequestParams,
  FetchUsersActivityResponse,
  UsersActivityFilters,
} from "types/usersActivity"
import requestFactory from "api/request"
import endpoints from "resources/endpoints"
import { Event } from "resources/event/eventTypes"

export const api = {
  ...endpoints,

  // TODO: move the endpoints below into the resources folder
  customer: {
    attribute: {
      value: {
        list(
          attributeId: Attribute["id"],
          value_contains = "",
          offset = 0,
          limit = 50,
          order_by?: keyof CustomerAttributeValueCount,
          order_dir: OrderDir = "ASC",
        ): Promise<CustomerAttributeValueCountResponse> {
          return requestFactory(
            "get",
            `/customers/attributes/${attributeId}/values/counts`,
            {
              offset,
              limit,
              order_by,
              order_dir,
              value_contains,
            },
            true,
            true,
            "",
            false,
            true,
          )
        },
      },
    },
  },
  attributesAggregations: {
    list<T extends Flag = 0>(
      offset = 0,
      limit = 20,
      order_by: keyof AttributeAggregationReturnType<T> = "order_index",
      order_dir: OrderDir = "ASC",
      load_full_structure: T = 0 as T,
      only_with_valid_attributes = true,
    ): Promise<AttributeAggregationListResponse<T>> {
      return requestFactory("get", "/attributes_aggregations", {
        offset,
        limit,
        order_by,
        order_dir,
        load_full_structure,
        only_with_valid_attributes,
      })
    },
    create<T extends Flag = 0>(
      data: AttributeAggregationCreatePayload,
      load_full_structure: T = 0 as T,
    ): Promise<{ attribute_aggregation: AttributeAggregationReturnType<T> }> {
      return requestFactory(
        "post",
        `/attributes_aggregations?load_full_structure=${load_full_structure}`,
        data,
      )
    },
    modify<T extends Flag = 0>(
      aggregation_id: AttributeAggregation["id"],
      data: AttributeAggregationModifyPayload,
      load_full_structure: T = 0 as T,
    ): Promise<{ attribute_aggregation: AttributeAggregationReturnType<T> }> {
      return requestFactory(
        "patch",
        `/attributes_aggregations/aggregations/${aggregation_id}?load_full_structure=${load_full_structure}`,
        data,
        true,
      )
    },
    delete(aggregation_id: AttributeAggregation["id"]): Promise<void> {
      return requestFactory(
        "delete",
        `/attributes_aggregations/aggregations/${aggregation_id}`,
        {},
        true,
      )
    },
  },
  cache: {
    delete(): Promise<void> {
      return requestFactory("delete", "/cache")
    },
  },
  customerEventsCount(
    start_date: DBFormatDateTime | null = null,
    end_date: DBFormatDateTime | null = null,
  ): Promise<{
    customer_events_count: number
    selection_settings: {
      start_date: DBFormatDateTime | null
      end_date: DBFormatDateTime | null
    }
  }> {
    return requestFactory("get", "/customer_events_count", { start_date, end_date })
  },
  customersCount(): Promise<{
    customer_entities_count: number
    customers_before_stitching_count: number
  }> {
    return requestFactory("get", "/customers_count")
  },
  eventsPerCustomerCount(): Promise<{ events_per_customer_count: number }> {
    return requestFactory("get", "/events_per_customer_count")
  },
  customerEventsPerEventType(): Promise<{
    customer_events_per_event_type: {
      source_id: Source["id"]
      event_type: Event["type"]
      count: number
    }[]
  }> {
    return requestFactory("get", "/customer_events_per_event_type")
  },
  customerEventsPerDate(
    start_date: DateString | null = null,
    end_date: DateString | null = null,
  ): Promise<{
    customer_events_per_date: {
      source_id: Source["id"]
      events_per_date: {
        date: DateString
        count: number
      }[]
    }[]
    resolution: "hour" | "day" | "week" | "month" | "year"
  }> {
    return requestFactory("get", "/customer_events_per_date", { start_date, end_date })
  },
  customersPerDate(
    start_date: DateString | null = null,
    end_date: DateString | null = null,
  ): Promise<{
    customers_per_date: {
      date: DateString
      count: number
    }[]
    resolution: "hour" | "day" | "week" | "month" | "year"
    selection_settings: {
      start_date: DateString | null
      end_date: DateString | null
    }
  }> {
    return requestFactory("get", "/customers_per_date", { start_date, end_date })
  },
  customersPerSource(): Promise<{
    customers_per_source: {
      source_id: Source["id"]
      count: number
    }[]
  }> {
    return requestFactory("get", "/customers_per_source")
  },
  customersPerSourcesCount(): Promise<{
    customers_per_sources_count: {
      source_count: number
      source_values: {
        source_ids: Source["id"][]
        customers_count: number
      }[]
    }[]
  }> {
    return requestFactory("get", "/customers_per_sources_count")
  },
  eventTypesLastEventTimes(): Promise<{
    last_event_times: {
      event_id: Event["id"]
      last_event_time: DBFormatDateTime
    }[]
  }> {
    return requestFactory("get", "/last_event_times")
  },
  customersLastUpdate(): Promise<{
    customer_last_update: {
      h12: number
      h24: number
      h48: number
      h96: number
      h168: number
      total: number
    }
  }> {
    return requestFactory("get", "/customer_last_update")
  },
  licenceUsage(): Promise<{
    customer_events_hidden_included_count: number | null
    customer_events_hidden_included_last_30_days_count: number | null
  }> {
    return requestFactory("get", "/licence_usage_counts")
  },
  suspiciousCustomerEntities(): Promise<{
    customers_with_events_excess: Array<{ customer_entity_id: string; events_count: number }>
    total_count: number
  }> {
    return requestFactory("get", "/customers_with_events_excess")
  },
  cacheStatus(): Promise<{
    init_time: DBFormatDateTime
    refresh_init_time: DBFormatDateTime | null
    is_refresh_running: boolean
  }> {
    return requestFactory("get", "/cdp_cache_status")
  },
  authOptions(): Promise<{
    is_credentials_login_enabled: boolean
    is_okta_login_enabled: boolean
    okta_auth_link: string
  }> {
    return requestFactory("get", "/users/auth_options")
  },
  allDataAggregations<T extends Flag = 0>(
    load_full_structure: T = 0 as T,
  ): Promise<AllDataAggregationsResponse<T>> {
    return requestFactory(
      "get",
      "/all_data_aggregations",
      { load_full_structure },
      false,
      true,
      "",
      true,
    )
  },
  userCounts(): Promise<FetchUserCountsResponse> {
    return requestFactory("get", "/users/users_stats")
  },
  usersActivity({
    since,
    until,
    filterType,
    filterValue,
  }: UsersActivityFilters): Promise<FetchUsersActivityResponse> {
    const requestParams: FetchUsersActivityRequestParams =
      filterType === "all_users"
        ? { since, until }
        : { since, until, [filterType]: filterValue.join(",") }

    return requestFactory("get", "/users/user_activity", requestParams)
  },
  tools: {
    textToDatetime(text: string, type: "date" | "datetime"): Promise<Record<string, string>> {
      return requestFactory("post", "/tools/datetime_from_text", [{ text, type }])
    },
  },
}
