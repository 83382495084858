import { useInfiniteQuery } from "@tanstack/react-query"
import { queryClient } from "app/queryClient"
import { Attribute } from "resources/attribute/attributeTypes"
import api from "resources/endpoints"
import create from "zustand"

const LIMIT = 20

const initialState = { searchTerm: "", attributeId: null, isFavoritesList: true }

export const useCustomerSearchStore = create(set => ({
  ...initialState,

  setSearch: ({ searchTerm, attributeId }: { searchTerm?: string; attributeId: Attribute["id"] }) =>
    set({ searchTerm, attributeId, isFavoritesList: false }),

  reset: () => set(initialState),
}))

function useFetchCustomerFulltextSearch({
  searchTerm,
  enabled,
}: {
  searchTerm?: string
  enabled: boolean
}) {
  const { data, ...rest } = useInfiniteQuery(
    ["customerSearch", "fulltext", searchTerm],
    ({ pageParam }) =>
      api.customers.searchFulltext({ searchTerm, offset: pageParam ?? 0, limit: LIMIT }),
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
      getNextPageParam({
        selection_settings: { offset, limit },
        customers_total_count,
        search_result,
      }) {
        if (
          limit === null ||
          offset === null ||
          search_result.length < limit ||
          search_result.length + offset === customers_total_count
        ) {
          return undefined
        }
        return offset + limit
      },
    },
  )
  return {
    ...rest,
    data: data && {
      data: data.pages.flatMap(p => p.search_result),
      totalCount: data.pages[0].customers_total_count,
      additionalAttribute: data.pages[0].additional_attribute,
    },
  }
}

function useFetchCustomerAttributeSearch({
  searchTerm,
  attributeId,
  enabled,
}: {
  searchTerm?: string
  attributeId: Attribute["id"]
  enabled: boolean
}) {
  const { data, ...rest } = useInfiniteQuery(
    ["customerSearch", "attribute", searchTerm, attributeId],
    ({ pageParam }) =>
      api.customers.searchByAttribute({
        searchTerm,
        attributeId,
        offset: pageParam ?? 0,
        limit: LIMIT,
      }),
    {
      enabled,
      retry: false,
      refetchOnWindowFocus: false,
      getNextPageParam({
        selection_settings: { offset, limit },
        customers_total_count,
        search_result,
      }) {
        if (
          limit === null ||
          offset === null ||
          search_result.length < limit ||
          search_result.length + offset === customers_total_count
        ) {
          return undefined
        }
        return offset + limit
      },
    },
  )
  return {
    ...rest,
    data: data && {
      data: data.pages.flatMap(p => p.search_result),
      totalCount: data.pages[0].customers_total_count,
      additionalAttribute: data.pages[0].additional_attribute,
    },
  }
}

export function invalidateFavoriteCustomers() {
  queryClient.invalidateQueries(["customerSearch", "favorite"])
}

function useFetchFavoriteCustomers() {
  const { data, ...rest } = useInfiniteQuery(
    ["customerSearch", "favorite"],
    ({ pageParam }) => api.customers.listFavorites({ offset: pageParam ?? 0, limit: LIMIT }),
    {
      getNextPageParam({ selection_settings: { offset, limit }, favourite_customers }) {
        if (limit === null || offset === null || favourite_customers.length < limit) {
          return undefined
        }
        return offset + limit
      },
    },
  )
  return {
    ...rest,
    data: data && {
      data: data.pages.flatMap(p => p.favourite_customers),
      totalCount: undefined,
      additionalAttribute: data.pages[0].additional_attribute,
    },
  }
}

export function useCustomerSearch({
  searchTerm,
  attributeId,
  isFavoritesList,
}: {
  searchTerm?: string
  attributeId: Attribute["id"] | null
  isFavoritesList: boolean
}) {
  const isAttributeSearch = !!attributeId
  // Favorites search is always enabled because it runs automatically on page load
  const {
    data: favoritesData,
    isLoading: isLoadingFavorites,
    fetchNextPage: fetchNextFavorites,
    isFetchingNextPage: isFetchingNextFavorites,
    hasNextPage: hasNextFavorites,
  } = useFetchFavoriteCustomers()
  const {
    data: fulltextSearchData,
    isLoading: isLoadingFulltextSearch,
    isFetching: isFetchingFulltextSearch,
    fetchNextPage: fetchNextFulltextSearch,
    isFetchingNextPage: isFetchingNextFulltextSearch,
    hasNextPage: hasNextFulltextSearch,
    error: errorFulltextSearch,
  } = useFetchCustomerFulltextSearch({
    searchTerm,
    enabled: !isFavoritesList && !isAttributeSearch,
  })
  const {
    data: attributeSearchData,
    isLoading: isLoadingAttributeSearch,
    isFetching: isFetchingAttributeSearch,
    fetchNextPage: fetchNextAttributeSearch,
    isFetchingNextPage: isFetchingNextAttributeSearch,
    hasNextPage: hasNextAttributeSearch,
    error: errorAttributeSearch,
  } = useFetchCustomerAttributeSearch({
    attributeId: attributeId!,
    searchTerm,
    enabled: !isFavoritesList && isAttributeSearch,
  })

  const data = isFavoritesList
    ? favoritesData
    : isAttributeSearch
    ? attributeSearchData
    : fulltextSearchData
  const isLoading = isFavoritesList
    ? isLoadingFavorites
    : isAttributeSearch
    ? isLoadingAttributeSearch && isFetchingAttributeSearch
    : isLoadingFulltextSearch && isFetchingFulltextSearch
  const fetchNextPage = isFavoritesList
    ? fetchNextFavorites
    : isAttributeSearch
    ? fetchNextAttributeSearch
    : fetchNextFulltextSearch
  const isFetchingNextPage = isFavoritesList
    ? isFetchingNextFavorites
    : isAttributeSearch
    ? isFetchingNextAttributeSearch
    : isFetchingNextFulltextSearch
  const hasNextPage = isFavoritesList
    ? hasNextFavorites
    : isAttributeSearch
    ? hasNextAttributeSearch
    : hasNextFulltextSearch
  const error = isAttributeSearch ? errorAttributeSearch : errorFulltextSearch

  return { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage, error }
}
