import React, { Component } from "react"
import _get from "lodash/get"
import _isString from "lodash/isString"
import SettingsForm from "./SettingsForm"
import { api } from "api"
import AllResourceItemsFetcher from "helpers/AllResourceItemsFetcher.helper"
import "./Settings.scss"
import { refetchAttributes } from "resources/attribute/attributeQueries"
import { refetchEvents } from "resources/event/eventQueries"
import { useHasAccess } from "resources/user/currentUserQueries"
import { refetchDataSources } from "resources/dataSource/dataSourceQueries"
import {
  useFetchGlobalSettings,
  useModifyGlobalSetting,
} from "resources/globalSettings/globalSettingsQueries"
import { showToast } from "actions/toast.action"
import { useDispatch } from "react-redux"
import { pick } from "ramda"

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tagListForSelect: [],
    }
  }

  async retrieveTags() {
    const caller = new AllResourceItemsFetcher()
    try {
      const data = await caller
        .setEndpointCall((offset, limit) => api.label.list(offset, limit, "name", "ASC"))
        .setDataPath("tags")
        .run()
      const tagListForSelect = data.map(tag => ({ value: tag.id, label: tag.name }))
      this.setState({ tagListForSelect })
    } catch (err) {}
  }

  componentDidMount() {
    this.retrieveTags()
  }

  onSubmit = async values => {
    const { modifyGlobalSetting, dispatch } = this.props

    const hasMiApiValues = _get(values, "mi_api.value.url", false)
    const hasMeApiValues = _get(values, "me_api.value.url", false)

    await Promise.all([
      modifyGlobalSetting(values.cdp_cache_expiration_period.id, {
        value: values.cdp_cache_expiration_period.value,
      }),
      modifyGlobalSetting(values.additional_search_result_attribute_id.id, {
        value: _get(values, "additional_search_result_attribute_id.value", ""),
      }),
      modifyGlobalSetting(values.channel_engagement_tag_id.id, {
        value: _get(values, "channel_engagement_tag_id.value", ""),
      }),
      modifyGlobalSetting(values.contact_info_tag_id.id, {
        value: _get(values, "contact_info_tag_id.value", ""),
      }),
      modifyGlobalSetting(values.maintenance_notifications_emails.id, {
        value: values.maintenance_notifications_emails.values,
      }),
      modifyGlobalSetting(values.mi_api.id, {
        value: hasMiApiValues ? values.mi_api.value : null,
      }),
      modifyGlobalSetting(values.me_api.id, {
        value: hasMeApiValues ? values.me_api.value : null,
      }),
    ]).then(() => {
      this.renewCache()
      // Toast here instead of in the mutation hook so that it doesn't show up a dozen times
      dispatch(showToast("Global setting modified."))
    })
  }

  renewCache = () => {
    const { hasAccess } = this.props
    this.retrieveTags()
    if (
      hasAccess.segments.insights ||
      hasAccess.data.dashboard ||
      hasAccess.data.insights ||
      hasAccess.data.events ||
      hasAccess.data.sourcesAndDestinations ||
      hasAccess.setup.implementation ||
      hasAccess.customers.detail
    ) {
      refetchDataSources()
    }
    refetchAttributes()
    refetchEvents()
  }

  render() {
    const { initialValues, areGlobalSettingsFulfilled } = this.props
    const { tagListForSelect } = this.state

    if (!areGlobalSettingsFulfilled) {
      return null
    }

    let transformedInitialValues = {
      ...initialValues,
      additional_search_result_attribute_id: {
        id: _get(initialValues, "additional_search_result_attribute_id.id", null),
        value: _get(initialValues, "additional_search_result_attribute_id.value", null),
      },
      contact_info_tag_id: pick(["id", "value"], initialValues.contact_info_tag_id),
      channel_engagement_tag_id: pick(["id", "value"], initialValues.channel_engagement_tag_id),
      maintenance_notifications_emails: {
        id: _get(initialValues, "maintenance_notifications_emails.id"),
        values: _isString(_get(initialValues, "maintenance_notifications_emails.value"))
          ? [_get(initialValues, "maintenance_notifications_emails.value")]
          : _get(initialValues, "maintenance_notifications_emails.value"),
      },
    }

    return (
      <SettingsForm
        tagListForSelect={tagListForSelect}
        onSubmit={this.onSubmit}
        initialValues={transformedInitialValues}
        handleCacheRenew={this.renewCache}
      />
    )
  }
}

export default props => {
  const dispatch = useDispatch()
  const hasAccess = useHasAccess()
  const { data: globalSettings, isSuccess: areGlobalSettingsFulfilled } = useFetchGlobalSettings()
  const modifyMutation = useModifyGlobalSetting()
  const modifyGlobalSetting = (id, data) => modifyMutation.mutateAsync({ id, data })

  return (
    <Settings
      {...props}
      dispatch={dispatch}
      hasAccess={hasAccess}
      initialValues={globalSettings}
      modifyGlobalSetting={modifyGlobalSetting}
      areGlobalSettingsFulfilled={areGlobalSettingsFulfilled}
    />
  )
}
