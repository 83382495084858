import React from "react"
import styles from "./NewReleaseModal.module.scss"
import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"
import { useModifyUser } from "resources/user/userQueries"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"

const CURRENT_NEW_FEATURE = "mobile_push_frequency_cap"

export default function NewReleaseModal() {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()

  const { id, frontend_settings } = currentUser!

  const handleClose = () => {
    modifyUser({
      id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          lastReleaseModalShown: CURRENT_NEW_FEATURE,
        },
      },
    })
  }

  const { lastReleaseModalShown } = frontend_settings ?? {}

  // @ts-ignore
  if (window.Cypress) return null

  return (
    <Modal
      open={lastReleaseModalShown !== CURRENT_NEW_FEATURE}
      title="🎉 Check out the updates:"
      handleClose={handleClose}
    >
      <div className={styles.container}>
        <h3>Frequency Caps for Mobile Push</h3>
        <p>
          Mobile Push now offers the possibility to control the number of notifications sent to
          users. Set global frequency caps to control delivery intervals across all push
          notification campaigns or use local frequency caps for individual campaigns. Learn more{" "}
          <strong>
            <a
              href="https://docs.meiro.io/books/meiro-business-explorer/page/mobile-push-how-to-set-frequencies"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </strong>
          {"."}
        </p>

        <div className={styles.buttonWrapper}>
          <Button size="md" onClick={handleClose}>
            Got it
          </Button>
        </div>
      </div>
    </Modal>
  )
}
