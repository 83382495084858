import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import _reduce from "lodash/reduce"
import _get from "lodash/get"

import toast from "./toast.reducer"
import usersActivityFilters from "./usersActivityFilters.reducer"

import { CUSTOMER, TABLE, INVITE_FORM, PERMISSION } from "sharedConstants"
import resourceReducers from "resources/resourceReducers"

const appReducer = combineReducers({
  ...resourceReducers,

  // TODO: move the reducers below into the resources folder
  toast,
  usersActivityFilters,
  form: formReducer.plugin({
    SegmentFilter: (state = {}, action) => {
      switch (action.type) {
        case `SegmentFilter_${TABLE.ACTION.SET_SORT_OPTIONS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order,
            },
          }
        case `SegmentFilter_${TABLE.ACTION.SET_SELECTED_TAGS}`:
          return {
            ...state,
            values: {
              ...state.values,
              selectedTags: action.payload.selectedTags,
            },
          }
        case `SegmentFilter_${TABLE.ACTION.SET_SORTING_AND_TAGS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order,
              selectedTags: action.payload.selectedTags,
            },
          }
        default:
          return state
      }
    },
    SetupSearchAttributeForm: (state = {}, action) => {
      switch (action.type) {
        case `SetupSearchAttributeForm_${TABLE.ACTION.SET_SORT_OPTIONS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order,
            },
          }
        case `SetupSearchAttributeForm_${TABLE.ACTION.SET_SELECTED_TAGS}`:
          return {
            ...state,
            values: {
              ...state.values,
              selectedTags: action.payload.selectedTags,
            },
          }
        default:
          return state
      }
    },
    DataTagsSearch: (state = {}, action) => {
      switch (action.type) {
        case `DataTagsSearch_${TABLE.ACTION.SET_SORT_OPTIONS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order,
            },
          }
        default:
          return state
      }
    },
    SearchCustomerForm: (state = {}, action) => {
      switch (action.type) {
        case CUSTOMER.ACTION.SEARCH_RESULTS_RESET:
          return {}
        case `SearchCustomerForm_${TABLE.ACTION.SET_SORT_OPTIONS}`:
          return {
            ...state,
            values: {
              ...state.values,
              orderBy: action.payload.column,
              orderDir: action.payload.order,
            },
          }
        default:
          return state
      }
    },
    CreateUserForm: (state, action) => {
      switch (action.type) {
        case `CreateUserForm_${INVITE_FORM.ACTION.SET_ACTIVE_USER}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "active-user": action.payload.index,
            },
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.SET_DEFAULT_READ_PERMISSIONS}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map(inviteUser => {
                let defaultPermission = null
                if (inviteUser.role.features.includes("foreign_segments/edit")) {
                  defaultPermission = PERMISSION.WRITE
                } else if (inviteUser.role.features.includes("foreign_segments/view")) {
                  defaultPermission = PERMISSION.READ
                }

                if (defaultPermission) {
                  return {
                    ...inviteUser,
                    "select-all": true,
                    "segment-permission": {
                      ...action.payload.segmentIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = defaultPermission
                        return acc
                      }, {}),
                      ...inviteUser["segment-permission"],
                    },
                    "segment-selected": {
                      ...action.payload.segmentIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = true
                        return acc
                      }, {}),
                      ...inviteUser["segment-selected"],
                    },
                  }
                } else {
                  return {
                    ...inviteUser,
                    "segment-permission": {
                      ...action.payload.segmentIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = PERMISSION.READ
                        return acc
                      }, {}),
                      ...inviteUser["segment-permission"],
                    },
                  }
                }
              }),
            },
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.TOGGLE_ALL_USER_PERMISSIONS}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  return {
                    ...inviteUser,
                    "segment-permission": _reduce(
                      inviteUser["segment-permission"],
                      (res, val, key) => {
                        res[key] = _get(inviteUser, `segment-selected.${key}`, false)
                          ? action.payload.value
                          : val
                        return res
                      },
                      {},
                    ),
                  }
                }
                return inviteUser
              }),
            },
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.MARK_ALL_SEGMENTS}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  return {
                    ...inviteUser,
                    "segment-selected": _reduce(
                      inviteUser["segment-permission"],
                      (res, val, key) => {
                        res[key] = action.payload.value
                        return res
                      },
                      {},
                    ),
                  }
                }
                return inviteUser
              }),
            },
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.TOGGLE_ALL_SWITCH}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  return {
                    ...inviteUser,
                    "toggle-all": action.payload.value,
                  }
                }
                return inviteUser
              }),
            },
          }
        }
        case `CreateUserForm_${INVITE_FORM.ACTION.SET_PERMISSIONS_MARK_ALL}`: {
          return {
            ...state,
            values: {
              ...state.values,
              "invite-users": state.values["invite-users"].map((inviteUser, index) => {
                if (index === action.payload.index) {
                  // add default permissions if necessary and mark all segments
                  return {
                    ...inviteUser,
                    "segment-permission": {
                      ...action.payload.segmentIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = PERMISSION.READ
                        return acc
                      }, {}),
                      ...inviteUser["segment-permission"],
                    },
                    "segment-selected": {
                      ...action.payload.segmentIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = action.payload.value
                        return acc
                      }, {}),
                    },
                  }
                } else {
                  // add default permissions otherwise, nothing to mark
                  return {
                    ...inviteUser,
                    "segment-permission": {
                      ...action.payload.segmentIds.reduce((acc, cur) => {
                        acc[`id-${cur}`] = PERMISSION.READ
                        return acc
                      }, {}),
                      ...inviteUser["segment-permission"],
                    },
                  }
                }
              }),
            },
          }
        }
        default:
          return state
      }
    },
  }),
})

// clear redux state on logout
const rootReducer = (state, action) => {
  if (action.type === "CLEAN_UP") {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
