import React from "react"
import classNames, { Argument } from "classnames"

import useCalcTextWidth from "hooks/useCalcTextWidth"
import { toStrPercentage } from "../../formatters"
import { Color } from "../../types"

import styles from "./ExpandedInsightChartBar.module.scss"

export const BAR_MAX_WIDTH = 500
export const GAP_WIDTH = 4

type ExpandedInsightChartBarProps = {
  percentage: number
  backBar?: boolean
  backBarWidth?: number
  color?: Color
  showLine?: boolean
  single?: boolean
  withinMultiBar?: boolean
}

export default function ExpandedInsightChartBar({
  color,
  backBarWidth,
  percentage,
  backBar = false,
  showLine = false,
  single = false,
  withinMultiBar = false,
}: ExpandedInsightChartBarProps) {
  const calcTextWidth = useCalcTextWidth()

  const strPercentage = toStrPercentage(percentage)
  const barWidth = (percentage / 100) * BAR_MAX_WIDTH
  const textWidth = calcTextWidth({ text: strPercentage }) + GAP_WIDTH
  const isTextNextToBar = textWidth > barWidth

  let isCoveringBackBar = false
  let lineWidth = showLine ? BAR_MAX_WIDTH - barWidth : 0
  if (isTextNextToBar) lineWidth = BAR_MAX_WIDTH - barWidth - textWidth

  if (backBarWidth !== undefined) {
    lineWidth -= backBarWidth
    isCoveringBackBar = barWidth + textWidth > backBarWidth
  }

  const barClassNameCommonArgs: Array<Argument> = [
    styles.bar,
    {
      [styles.withinMultiBar]: withinMultiBar,
      [styles.backBar]: withinMultiBar && backBar,
      [styles.single]: single,
    },
  ]
  const barClassName = color
    ? classNames(styles.colored, styles[color], ...barClassNameCommonArgs)
    : classNames(styles.grey, ...barClassNameCommonArgs)

  const percentageClassNameCommonArgs: Array<Argument> = [
    {
      [styles.withinMultiBar]: withinMultiBar && !isCoveringBackBar,
      [styles.nextTo]: isTextNextToBar,
    },
  ]
  const percentageClassName = color
    ? classNames(styles.colored, styles[color], ...percentageClassNameCommonArgs)
    : classNames(styles.grey, ...percentageClassNameCommonArgs)

  return (
    <>
      {showLine && (
        <div
          className={styles.line}
          style={{
            width: `${lineWidth}px`,
          }}
        />
      )}
      <div className={barClassName} style={{ width: `${barWidth}px` }}>
        {!backBar && (
          <span className={percentageClassName} style={{ right: `-${textWidth}px` }}>
            {strPercentage}
          </span>
        )}
      </div>
    </>
  )
}
