import React from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"

import { getRoutePath } from "routes"
import MetaAttributesForm from "./components/MetaAttributesForm/MetaAttributesForm"
import {
  useFetchMetaAttribute,
  useModifyMetaAttribute,
} from "resources/metaAttributes/metaAttributesQueries"

export default function MetaAttributesDetail() {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const { data: metaAttribute, isError, isLoading } = useFetchMetaAttribute(id)
  const { mutateAsync } = useModifyMetaAttribute()

  if (metaAttribute === null) return <Redirect to={getRoutePath("not-found")} />
  if (isError) return null

  return (
    <MetaAttributesForm
      metaAttribute={metaAttribute}
      onCancel={() => history.push(getRoutePath("administration.metaAttributes"))}
      onSubmit={({ id, ...rest }) => mutateAsync({ id, data: rest })}
      isLoading={isLoading}
    />
  )
}
