import requestFactory from "api/request"
import { Attribute } from "resources/attribute/attributeTypes"
import {
  CustomerAttributeSearchResponseFull,
  CustomerFulltextSearchResponseFull,
  FavoriteCustomersListResponseFull,
} from "./customerSearchTypes"

const customers = {
  searchFulltext({
    searchTerm,
    offset,
    limit,
  }: {
    searchTerm?: string
    offset: number
    limit: number
  }): Promise<CustomerFulltextSearchResponseFull> {
    return requestFactory("get", "/customers/fulltext_search", {
      search_text: searchTerm,
      offset,
      limit,
      load_full_structure: 1,
    })
  },
  searchByAttribute({
    attributeId,
    searchTerm = "",
    offset,
    limit,
  }: {
    attributeId: Attribute["id"]
    searchTerm?: string
    offset: number
    limit: number
  }): Promise<CustomerAttributeSearchResponseFull> {
    return requestFactory("get", `/customers/attributes/${attributeId}/search`, {
      search_value: searchTerm,
      offset,
      limit,
      load_full_structure: 1,
    })
  },
  listFavorites({
    offset,
    limit,
  }: {
    offset: number
    limit: number
  }): Promise<FavoriteCustomersListResponseFull> {
    return requestFactory("get", "/customers/favourite/search", {
      offset,
      limit,
      load_full_structure: 1,
    })
  },
}

export default customers
