import React, { PureComponent, useEffect } from "react"
import { connect } from "react-redux"
import { Form, Field, reduxForm } from "redux-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import Waypoint from "react-waypoint"
import { getFormValues } from "redux-form"
import { isEmpty, whereEq } from "ramda"
import { showToast } from "actions/toast.action"

// ui components
import Paper from "components/UI/elements/Paper"
import SearchField from "components/UI/elements/SearchField"
import CustomerAvatar from "components/UI/elements/CustomerAvatar"
import AttributePicker from "components/UI/components/AttributePicker/AttributePicker"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import CompoundAttributeValuesTable from "components/UI/elements/CompoundAttributeValuesTable/CompoundAttributeValuesTable"
import Table, { Thead, Th, Tbody, Td, Tr } from "components/UI/elements/Table"
// api, helpers
import {
  shortenTextArroundPattern,
  shortenText,
  shortenTextWithoutDots,
} from "helpers/string.helper"
import { formatSearchNumber } from "helpers/number.helper"
import { getRoutePath } from "routes"
import {
  getUserFriendlyValueFormat,
  getBackendValueFromUserFriendly,
} from "helpers/attributeValue.helper"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { isJSONString } from "helpers/validators.helper"

//constants
import { TOAST } from "sharedConstants"

import "./ProfilesList.scss"
import Tippy from "@tippyjs/react"
import classNames from "classnames"
import { range } from "lodash"
import DelayedTooltip from "components/UI/elements/DelayedTooltip/DelayedTooltip"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import {
  useFetchCurrentUser,
  useHasAccess,
  useToggleFavoriteCustomer,
} from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"
import Page from "components/UI/Page/Page"
import { useProfileIteratorStore } from "resources/profile/profileIterator"
import {
  useCustomerSearch,
  useCustomerSearchStore,
} from "resources/customer/search/customerSearchQueries"

class ProfilesList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      toggledFavoriteCustomerId: null,
      rowsExpanded: [],
    }
  }

  toggleFavoriteCustomer = async id => {
    this.setState({ toggledFavoriteCustomerId: id })
    try {
      await this.props.toggleFavoriteCustomer(id)
    } catch {
      // noop
    }
    this.setState({ toggledFavoriteCustomerId: null })
  }

  onSubmit = values => {
    const { attributesMap, setLatestSearch, showToast, currentUser, setSearch } = this.props

    const trimmedSearchText = values.searchText?.trim()

    if (
      trimmedSearchText !== undefined &&
      trimmedSearchText !== "" &&
      trimmedSearchText.length < 3
    ) {
      showToast("Please type in at least 3 characters into the search field.", TOAST.TYPE.ERROR)
      return
    }

    if (!values.attributeId) {
      // case 1) only search text, fulltext call
      if (trimmedSearchText) {
        let customerSearches = currentUser.frontend_settings?.latestSearch?.customers
        if (Array.isArray(customerSearches)) {
          if (!this.searchExists(customerSearches, trimmedSearchText, null)) {
            setLatestSearch([
              ...customerSearches.slice(-4),
              { searchText: trimmedSearchText, attributeId: null },
            ])
          }
        } else {
          setLatestSearch([{ searchText: trimmedSearchText }])
        }
      }

      setSearch({ searchTerm: trimmedSearchText, attributeId: null })
    } else {
      // convert search text from user friendly values to backend values
      const attribute = attributesMap[values.attributeId]
      const searchText = getBackendValueFromUserFriendly(trimmedSearchText, attribute?.data_type)

      let customerSearches = currentUser.frontend_settings?.latestSearch?.customers
      if (Array.isArray(customerSearches)) {
        if (!this.searchExists(customerSearches, trimmedSearchText, values.attributeId)) {
          setLatestSearch([
            ...customerSearches.slice(-4),
            { searchText, attributeId: values.attributeId },
          ])
        }
      } else {
        setLatestSearch([{ searchText, attributeId: values.attributeId }])
      }

      setSearch({ searchTerm: searchText, attributeId: values.attributeId })
    }
  }

  searchExists = (customerSearches, searchText, attributeId) =>
    customerSearches.find(whereEq({ searchText, attributeId }))

  setCustomersPaginationIterator = arrIndex => () => {
    this.props.setProfileIteratorIndex(arrIndex)
  }

  renderCustomerRow = (customer, customerIndex, searchText) => {
    const { currentUser, queryResult, setProfileIteratorIndex, hasAccess } = this.props
    const additionalAttribute = queryResult?.additionalAttribute ?? null
    let additionalAttributeValue = _get(customer, "additional_attribute_values[0]", "—")
    const hasAdditionalAttributeMoreValues =
      _get(customer, "additional_attribute_values.length") > 1
    if (additionalAttribute && additionalAttributeValue !== "—") {
      additionalAttributeValue = getUserFriendlyValueFormat(
        additionalAttributeValue,
        additionalAttribute.data_type,
      )
    }

    const { id } = customer.customer_entity
    const isCustomerFavorite = currentUser.cdp_settings?.favourite_customers?.find(
      ({ customer_entity_id }) => customer_entity_id === id,
    )
    const showAsFavorite =
      // between the click and the response coming back from the server, already show the button in
      // the new state
      (isCustomerFavorite && this.state.toggledFavoriteCustomerId !== id) ||
      (!isCustomerFavorite && this.state.toggledFavoriteCustomerId === id)

    const onRowClick = evt => {
      if (!hasAccess.customers.detail) {
        evt.preventDefault()
      }
      if (hasAccess.customers.detail) {
        setProfileIteratorIndex(customerIndex)
      }
    }

    const rowHref = {
      pathname: getRoutePath("profiles.detail", { id: customer.customer_entity.id }),
      state: { goBack: true, prev: "search" },
    }

    const favoriteButtonCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "favorite-button-cell", {
          "no-border": withoutBorder,
        })}
      >
        <Tippy content={showAsFavorite ? "Remove from favorites" : "Add to favorites"}>
          <button
            className={classNames("favorite-button", { "is-faved": showAsFavorite })}
            onClick={e => {
              e.preventDefault()
              this.toggleFavoriteCustomer(id)
            }}
            disabled={this.state.toggledFavoriteCustomerId === id}
          >
            <FontAwesomeIcon icon={[showAsFavorite ? "fas" : "far", "star"]} />
          </button>
        </Tippy>
      </Td>
    )

    const avatarCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "customer-avatar-cell", {
          "no-border": withoutBorder,
        })}
      >
        <CustomerAvatar className="customer-avatar" />
      </Td>
    )

    const idCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "customer-id", {
          "no-border": withoutBorder,
        })}
        textBigger
        textBlack
        textBold
      >
        {shortenText(customer.customer_entity.id, 10)}
      </Td>
    )

    const additionalAttributeCell = withoutBorder =>
      additionalAttribute !== null && (
        <Td className={classNames("customer-table-cell", { "no-border": withoutBorder })}>
          {(additionalAttributeValue.length > 30 || hasAdditionalAttributeMoreValues) && (
            <React.Fragment>
              <Tippy
                content={_get(customer, "additional_attribute_values", []).map((val, index) => {
                  return (
                    <span className="add-value" key={index}>
                      {getUserFriendlyValueFormat(val, additionalAttribute.data_type)}
                    </span>
                  )
                })}
              >
                <span>{shortenTextWithoutDots(additionalAttributeValue, 30)}...</span>
              </Tippy>
            </React.Fragment>
          )}
          {additionalAttributeValue.length <= 30 && !hasAdditionalAttributeMoreValues && (
            <React.Fragment>{additionalAttributeValue}</React.Fragment>
          )}
        </Td>
      )

    const expandOrCompactRow = customerIndex => {
      this.setState(({ rowsExpanded }) => ({
        rowsExpanded: rowsExpanded.includes(customerIndex)
          ? rowsExpanded.filter(el => el !== customerIndex)
          : rowsExpanded.concat(customerIndex),
      }))
    }

    const chevronCellExpand = customerIndex => {
      const isRowExpanded = this.state.rowsExpanded.includes(customerIndex)
      return (
        <Td className={classNames("chevron-cell", "no-border")}>
          <DelayedTooltip content={isRowExpanded ? "Compact" : "Expand"}>
            <button
              className={classNames("expand-button")}
              onClick={e => {
                expandOrCompactRow(customerIndex)
                e.preventDefault()
              }}
            >
              <FontAwesomeIcon
                className="chevron-down"
                icon={["fas", isRowExpanded ? "chevron-up" : "chevron-down"]}
              />
            </button>
          </DelayedTooltip>
        </Td>
      )
    }

    const chevronCell = withoutBorder => (
      <Td
        className={classNames("customer-table-cell", "chevron-cell", {
          "no-border": withoutBorder,
        })}
      >
        <FontAwesomeIcon className="chevron-right" icon={["fas", "chevron-right"]} />
      </Td>
    )

    if (customer.customer_attributes.length === 0) {
      return (
        <Tr
          key={`${customerIndex}-0`}
          href={rowHref}
          onClick={onRowClick}
          disabled={!hasAccess.customers.detail}
        >
          {favoriteButtonCell()}
          {avatarCell()}
          {idCell()}
          {additionalAttributeCell()}
          <Td className="customer-table-cell">&nbsp;</Td>
          <Td className="customer-table-cell">&nbsp;</Td>
          <Td className="customer-table-cell">&nbsp;</Td>
          {chevronCell()}
        </Tr>
      )
    }

    return customer.customer_attributes.map((ca, valueIndex) => {
      const attributesCount = customer.customer_attributes.length
      const value = ca.value ? ca.value : "N/A"
      let valueDOM = null
      if (isAttributeCompound(ca.attribute.data_type) && isJSONString(value)) {
        const subAttributes = getCompoundAttributeSubAttributes(ca.attribute.data_type)
        const values = [JSON.parse(value)]
        valueDOM = (
          <div className="compound-value-wrapper">
            <CompoundAttributeValuesTable subAttributes={subAttributes} values={values} />
          </div>
        )
      } else {
        if (value.length > 40) {
          valueDOM = (
            <React.Fragment>
              <Tippy content={value}>
                <span>{shortenTextArroundPattern(searchText, value, 40)}</span>
              </Tippy>
            </React.Fragment>
          )
        } else {
          valueDOM = getUserFriendlyValueFormat(value, ca.attribute.data_type)
        }
      }

      const numberOfLeadingEmptyCells = additionalAttribute !== null ? 4 : 3
      return (
        <Tr
          key={`${customerIndex}-${valueIndex}`}
          href={rowHref}
          onClick={onRowClick}
          disabled={!hasAccess.customers.detail}
          className={
            this.state.rowsExpanded.includes(customerIndex) === false && valueIndex > 0
              ? "expandedHidden"
              : "expandedVisible"
          }
        >
          {valueIndex === 0 && (
            <>
              {favoriteButtonCell(attributesCount > 1)}
              {avatarCell(attributesCount > 1)}
              {idCell(attributesCount > 1)}
            </>
          )}
          {valueIndex > 0 &&
            range(0, numberOfLeadingEmptyCells).map(key => (
              <Td
                key={key}
                className={classNames("customer-table-cell", "smaller", {
                  "no-border": valueIndex < attributesCount - 1,
                })}
              >
                &nbsp;
              </Td>
            ))}
          {valueIndex === 0 && additionalAttributeCell(attributesCount > 1)}
          <Td className={classNames("customer-table-cell", { smaller: valueIndex > 0 })}>
            {ca.attribute.source.name}
          </Td>
          <Td className={classNames("customer-table-cell", "no-wrap", { smaller: valueIndex > 0 })}>
            {ca.attribute.name.length > 20 && (
              <React.Fragment>
                <Tippy content={ca.attribute.name}>
                  <span>{shortenText(ca.attribute.name, 20)}</span>
                </Tippy>
              </React.Fragment>
            )}
            {ca.attribute.name.length <= 20 && ca.attribute.name}
          </Td>
          <Td className={classNames("customer-table-cell", "no-wrap", { smaller: valueIndex > 0 })}>
            {valueDOM}
          </Td>
          {valueIndex === 0 && (attributesCount > 1 ? chevronCellExpand(customerIndex) : <Td></Td>)}
          {valueIndex > 0 && (
            <Td
              className={classNames("customer-table-cell", "smaller", {
                "no-border": valueIndex < attributesCount - 1,
              })}
            >
              &nbsp;
            </Td>
          )}
        </Tr>
      )
    })
  }

  /*
   * It executes defined onEnter function whenever user scrolls to
   * the element 'Waypoint'. We are using it for infinite scrolling.
   */
  renderWaypoint = () => {
    const { hasNextPage, isFetchingNextPage, fetchNextPage } = this.props

    if (hasNextPage && !isFetchingNextPage) {
      return <Waypoint onEnter={fetchNextPage} bottomOffset={-600} />
    } else if (isFetchingNextPage) {
      return <LoadingIndicator />
    }
  }

  selectAttribute = attributeId => {
    this.props.change("attributeId", attributeId)
  }

  repeatSearch = (attributeId, searchText) => {
    const { change } = this.props
    change("attributeId", attributeId)
    change("searchText", searchText)
    this.onSubmit({
      searchText: searchText,
      attributeId: attributeId,
    })
  }

  renderLatestSearch = () => {
    const { currentUser, attributesMap } = this.props
    const searchValues = currentUser.frontend_settings?.latestSearch?.customers ?? []

    if (_isEmpty(searchValues)) {
      return null
    }
    let attributeText = "Profile highlights"
    let lastSearches = searchValues.map(searchValue => {
      if (searchValue.attributeId) {
        const attribute = attributesMap[searchValue.attributeId]
        if (attribute) {
          attributeText = `${attribute.source.name}: ${attribute.name}`
        } else {
          attributeText = "Attribute no longer exists"
        }
      }

      return {
        attributeText: attributeText,
        searchText: searchValue.searchText,
        attributeId: searchValue.attributeId,
      }
    })

    return (
      <div className="latest-search">
        <div className="last-search-label">Last search:</div>
        {_isEmpty(attributesMap) && <LoadingIndicator size="sm" fixedWidth />}
        {!isEmpty(attributesMap) &&
          lastSearches.map(searchedItem => (
            <div
              key={`${searchedItem.attributeId}-${searchedItem.searchText}`}
              className="search-box"
              onClick={() => this.repeatSearch(searchedItem.attributeId, searchedItem.searchText)}
            >
              <div className="searched-text">
                {searchedItem.searchText ? (
                  <Tippy content={searchedItem.searchText}>
                    <strong>{shortenText(searchedItem.searchText, 15)}</strong>
                  </Tippy>
                ) : (
                  <strong>───</strong>
                )}
              </div>
              <Tippy content={searchedItem.attributeText}>
                <div className="bottom-text">
                  {searchedItem.attributeId !== null
                    ? shortenText(searchedItem.attributeText, 25)
                    : "Profile highlights"}
                </div>
              </Tippy>
            </div>
          ))}
      </div>
    )
  }

  clearSearchText = () => {
    const { change } = this.props
    change("searchText", "")
  }

  render() {
    const { handleSubmit, filterValues, queryResult, searchTerm, isLoading, isFavoritesList } =
      this.props

    const attributeId = _get(filterValues, "attributeId", null)

    const message = isFavoritesList ? (
      <p className="info-message">
        <span>Specify attribute</span> or <span>Search</span>.
      </p>
    ) : queryResult?.data.length > 0 ? undefined : (
      <p className="info-message">
        <strong>No results found.</strong> Please try different criteria.
      </p>
    )

    return (
      <Page
        title="Profiles search"
        headerContent={
          <div className="customers-search-position">
            <Form
              autoComplete="off"
              className="customers-search-form"
              onSubmit={handleSubmit(this.onSubmit)}
            >
              <div className="customers-list-attribute-picker">
                <AttributePicker
                  attributeId={attributeId}
                  isEditable
                  handleAttributeSelect={this.selectAttribute}
                  fixedSize="large-size"
                  placeholder="Profile highlights"
                  inputTextLimit={22}
                  isClearable
                />
              </div>
              <Field
                name="searchText"
                component={SearchField}
                placeholder="Search attributes, profiles and more..."
                className="large search-box"
                autoFocus={true}
                type="text"
                onClear={this.clearSearchText}
              />
            </Form>
          </div>
        }
        className="customers-list"
      >
        {isLoading && <LoadingIndicator />}
        {!isLoading && queryResult && (
          <Paper hasHeader={true} className="customers-list-content">
            {queryResult.totalCount > 0 && (
              <p className="number-of-results">
                <strong>{formatSearchNumber(queryResult.totalCount)}</strong>{" "}
                {queryResult.totalCount === 1 ? "result" : "results"}
              </p>
            )}
            {this.renderLatestSearch()}
            {message !== "" && message}

            {queryResult.data.length > 0 && (
              <Table className="customers-search">
                <Thead stickyHeader>
                  <Th></Th>
                  <Th className="avatar">&nbsp;</Th>
                  <Th>ID</Th>
                  {queryResult.additionalAttribute !== null && (
                    <Th className="additional-attribute">{queryResult.additionalAttribute.name}</Th>
                  )}
                  <Th>Source</Th>
                  <Th>Attribute</Th>
                  <Th>Value</Th>
                  <Th className="chevron-head">&nbsp;</Th>
                </Thead>
                <Tbody>
                  {queryResult.data.map((customer, index) =>
                    this.renderCustomerRow(customer, index, searchTerm),
                  )}
                </Tbody>
              </Table>
            )}
            {this.renderWaypoint()}
          </Paper>
        )}
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  filterValues: getFormValues("SearchCustomerForm")(state),
})

ProfilesList = reduxForm({
  form: "SearchCustomerForm",
  touchOnBlur: false,
  destroyOnUnmount: false,
})(
  connect(mapStateToProps, {
    showToast,
  })(ProfilesList),
)

export default props => {
  const { data: attributesMap = {} } = useFetchAttributesMap()
  const toggleFavoriteCustomer = useToggleFavoriteCustomer()
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()
  const hasAccess = useHasAccess()
  const { id, frontend_settings } = currentUser
  const { latestSearch = {} } = frontend_settings ?? {}
  const { setIndex } = useProfileIteratorStore()

  const { attributeId, searchTerm, isFavoritesList, setSearch, reset } = useCustomerSearchStore()
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, error } =
    useCustomerSearch({
      attributeId,
      searchTerm,
      isFavoritesList,
    })

  useEffect(() => {
    if (error) {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const setLatestSearch = customers => {
    modifyUser({
      id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          latestSearch: {
            ...latestSearch,
            customers,
          },
        },
      },
    })
  }

  return (
    <ProfilesList
      {...props}
      attributesMap={attributesMap}
      currentUser={currentUser}
      toggleFavoriteCustomer={toggleFavoriteCustomer}
      setLatestSearch={setLatestSearch}
      hasAccess={hasAccess}
      setProfileIteratorIndex={setIndex}
      setSearch={setSearch}
      queryResult={data}
      isLoading={isLoading}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFavoritesList={isFavoritesList}
      searchTerm={searchTerm}
    />
  )
}
